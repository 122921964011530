import React, { useState } from "react";
import Logo from "../../images/Logo";
import LetterLogo from "../../images/LetterLogo";
import HMPEcosystemImage from "../../images/HMPEcosystem.png";
import HMPAppImage from "../../images/HMPApp.png";
import HMPLogoImage from "../../images/HMPLogo.png";
import AndroidQRImage from "../../images/AndroidQR.png";
import iOSQRImage from "../../images/iOSQR.png";
import CryptoTokensImage from "../../images/CryptoTokens.png";
import Map3LogoImage from "../../images/Map3Logo.png";
import StockImage from "../../images/StockImage.png";
import UpperRightLogo from "../../images/UpperRightLogo.svg";
import LowerLeftLogo from "../../images/LowerLeftLogo.svg";
import HyunYuImage from "../../images/HyunYu.png";
import EricChoiImage from "../../images/EricChoi.png";
import BongakGuImage from "../../images/BongakGu.png";
import DannyKimImage from "../../images/DannyKim.png";
import PartnersImage from "../../images/Partners.png";
import MenuImage from "../../images/Menu.png";
import Map3BridgeImage from "../../images/Map3Bridge.png";
import Map3BigLogoImage from "../../images/Map3BigLogo.png";
import ProtocolEcosystemImage from "../../images/ProtocolEcosystem.png";
import MonitorVideo from "../../images/MonitorVideo.mp4";
import SAVTokenImage from "../../images/SAVToken.png";
import RightArrowImage from "../../images/RightArrow.png";
import WorldCoinLogoImage from "../../images/WorldCoinLogo.png";
import XIconImage from "../../images/XIcon.png";
import InstagramIconImage from "../../images/InstagramIcon.png";
import WhatsAppIconImage from "../../images/WhatsAppIcon.png";
import WalkerhillLogoImage from "../../images/WalkerhillLogo.png";
import SKNetworksLogoImage from "../../images/SKNetworks.png";
import HuntTownImage from "../../images/HuntTown.png";
import TheInventionLabLogoImage from "../../images/TheInventionLab.png";
import IOTrustLogoImage from "../../images/IOTrust.png";
import MenuBar from "./MenuBar"; // Ensure this path is correct
import PartnersLoopAnimation from "../../images/PartnersLoopAnimation.mp4";
import SAVTokensVideo from "../../images/SAVTokens.mp4";
import Header from "./Header";
import Footer from "./Footer";
import ProtocolEcosystemVideo from "../../images/ProtocolEcosystem.mp4";

import "./Home.css";

const scrollToWeb3Section = () => {
  const web3Section = document.getElementById("web3-section");
  if (web3Section) {
    web3Section.scrollIntoView({ behavior: "smooth" });
  }
};

function Home() {
  const [isMenuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  const scrollToTop = () => {
    const targetElement = document.getElementById("web3-intro");
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="w-full min-h-screen bg-black overflow-x-hidden">
      <div className="w-full max-w-[1440px] mx-auto relative">
        <div className="relative bg-black">
          <Header
            toggleMenu={toggleMenu}
            scrollToTop={scrollToTop}
            isMenuVisible={isMenuVisible}
          />
          <div
            className="w-[1440px] h-[970px] left-0 top-0 absolute"
            id="web3-intro"
          >
            <div className="w-[1440px] h-[970px] left-0 top-0 absolute">
              <img
                className="w-full h-full object-cover absolute top-0 left-0 z-0 opacity-70"
                src={StockImage}
                alt="Stock background"
                style={{ filter: "brightness(0.7)" }}
              />
            </div>
            <div className="w-[1440px] h-[312px] left-0 top-[658px] absolute">
              <div className="w-[1440px] h-[312px] left-0 top-0 absolute bg-gradient-to-b from-transparent to-black" />
            </div>
          </div>
          <div className="w-[1440px] h-[472px] left-0 top-[298px] absolute flex-col justify-start items-start gap-2.5 inline-flex">
            <div className="self-stretch px-[200px] py-2.5 justify-start items-center gap-2.5 inline-flex">
              <div className="text-white text-[80px] font-bold font-['PT Sans'] leading-[80px]">
                We Bring Web3 to <br />
                Real Life
              </div>
            </div>
            <div className="self-stretch px-[200px] py-2.5 justify-start items-center inline-flex">
              <div className="w-[652px] text-white text-2xl font-normal font-['PT Sans'] leading-7">
                It is a protocol that allows you to use your NFT
                <br />
                as a membership that allows you to receive various benefits
                <br />
                in real-world stores.
              </div>
            </div>
            <div className="h-[72px] p-2.5 flex-col justify-center items-center gap-2.5 flex absolute bottom-8 left-0 right-0">
              <div
                className="flex flex-col items-center cursor-pointer"
                onClick={scrollToWeb3Section}
              >
                <div
                  id="web3-section"
                  className="text-center text-white text-xl font-normal font-['PT Mono'] leading-9 mb-2 animate-pulse animate-pulse-size"
                >
                  Explore More
                </div>
                <div className="w-0 h-0 border-l-[10px] border-l-transparent border-r-[10px] border-r-transparent border-t-[10px] border-t-white animate-bounce"></div>
              </div>
            </div>
          </div>
          <img
            className="w-[443.03px] h-[335.34px] left-[269.65px] top-[3178px] absolute origin-top-left rotate-[13.74deg]"
            src={HMPAppImage}
            alt="HMP App"
            loading="lazy"
            decoding="async"
          />
          <div className="left-[730px] top-[3195.78px] absolute text-white text-4xl font-bold font-['PT Mono'] leading-10">
            Hidemeplease APP
          </div>
          <div className="left-[412px] top-[2379px] absolute text-center text-white text-5xl font-bold font-['PT Mono'] leading-[72px]">
            Map3Protocol Ecosystem{" "}
          </div>
          <div className="left-[730px] top-[4592px] absolute text-white text-4xl font-bold font-['PT Mono'] leading-10">
            Savory Tokenomics
            <br />
            Hide to Earn (H2E)
          </div>
          <div className="left-[730px] top-[3413.78px] absolute text-[#ef5230] text-2xl font-normal font-['PT Sans'] leading-normal mt-6">
            F&B Partners
          </div>
          <div className="left-[730px] top-[3438.86px] absolute text-[#ef5230] text-5xl font-bold font-['PT Sans'] leading-[72px] mt-6">
            130 +
          </div>
          <div className="left-[995px] top-[3413.78px] absolute text-[#ef5230] text-2xl font-normal font-['PT Sans'] leading-normal mt-6">
            Users
          </div>
          <div className="left-[995px] top-[3438.78px] absolute text-[#ef5230] text-5xl font-bold font-['PT Sans'] leading-[72px] mt-6">
            15,300 +
          </div>
          <div className="w-[510px] left-[730px] top-[3265.78px] absolute">
            <div className="text-white text-xl font-normal font-['PT Sans'] leading-normal mb-8">
              It is an app that allows users to register their NFTs as
              membership cards and enjoy discounts at affiliated F&B stores
              around the world. You can enjoy stores with cryptocurrency
              payments, and users can check their favorite members' activities
              at affiliated stores in real time.
            </div>
            <div className="left-[730px] top-[3413.78px] absolute text-[#ef5230] text-2xl font-normal font-['PT Sans'] leading-normal mt-8">
              F&B Partners
            </div>
            <div className="left-[730px] top-[3438.86px] absolute text-[#ef5230] text-5xl font-bold font-['PT Sans'] leading-[72px]">
              100 +
            </div>
            <div className="left-[995px] top-[3413.78px] absolute text-[#ef5230] text-2xl font-normal font-['PT Sans'] leading-normal">
              Users
            </div>
            <div className="left-[995px] top-[3438.78px] absolute text-[#ef5230] text-5xl font-bold font-['PT Sans'] leading-[72px]">
              15,300 +
            </div>
          </div>
          <div className="w-[510px] left-[730px] top-[4705px] absolute text-white text-xl font-normal font-['PT Sans'] leading-normal">
            H2E emphasizes interactive hiding activities using NFC tags at
            affiliated stores, allowing users to mine tokens and earn unique
            rewards beyond simple visits. <br />
          </div>
          <div className="w-[211.47px] h-[191.31px] left-[204px] top-[3423.47px] absolute">
            <div className="w-[210.90px] h-[46.10px] left-[-17px] top-[145.21px] absolute text-center text-white text-base font-normal font-['PT Mono'] leading-tight">
              Download Now and
              <br />
              Don't Miss Benefits
            </div>
            <div className="w-[87.59px] h-[16.13px] left-0 top-0 absolute text-center text-white text-base font-normal font-['PT Mono'] leading-none">
              android
            </div>
            <div className="w-[56.47px] h-[16.13px] left-[112px] top-0 absolute text-center text-white text-base font-normal font-['PT Mono'] leading-none">
              ios
            </div>
            <div className="w-[179px] h-[89px] left-[4px] top-[34.53px] absolute justify-start items-center gap-4 inline-flex">
              <img
                className="w-20 h-20 rounded"
                src={AndroidQRImage}
                alt="Android QR Code"
                loading="lazy"
                decoding="async"
              />
              <img
                className="w-20 h-20 rounded"
                src={iOSQRImage}
                alt="iOS QR Code"
                loading="lazy"
                decoding="async"
              />
            </div>
          </div>
          <div className="w-[1440px] px-[200px] py-2.5 left-0 top-[863px] absolute justify-start items-center inline-flex">
            <div className="text-white text-[80px] font-bold font-['PT Sans'] leading-[84px]">
              WEB3's cool, but not practical yet...
            </div>
          </div>
          <div className="bg-[#EF5230] absolute left-[0px] top-[1541px] w-[1440px] h-[770px] z-0">
            {" "}
          </div>
          <div className="w-[652px] h-[253px] left-[658px] top-[1777px] absolute flex-col justify-end items-center gap-2.5 inline-flex">
            <img
              className="self-stretch h-[253px] object-cover animate-pulse-size"
              src={Map3BridgeImage}
              alt="Map3 Bridge"
              loading="lazy"
              decoding="async"
            />
          </div>

          <div className="w-[516px] left-[204px] top-[1641px] absolute text-black text-[80px] font-bold font-['PT Sans'] leading-[84px]">
            Make a new connection
          </div>
          <div className="w-[636px] left-[674px] top-[2143px] absolute text-black text-xl font-bold font-['PT Sans'] leading-normal">
            Building a sustainable token economy model is essential to the
            long-term success of the Web3 ecosystem, and its integration with
            the real world is an important factor that promotes popularization
            and is easily accessible to more users.
          </div>
          <div className="w-[636px] h-24 left-[674px] top-[2042px] absolute text-black text-[32px] font-bold font-['PT Sans'] leading-10">
            Map3 technology effectively integrates Web3 with the Real World.
          </div>
          <div className="w-[663px] h-[340px] absolute left-[0px] top-[1756px] w-[1440px] h-[770px] z-0">
            <img
              src={Map3BigLogoImage}
              alt="Map3 Big Logo"
              className="w-full h-full object-contain"
              loading="lazy"
              decoding="async"
            />
          </div>
          <div className="left-[200px] top-[3770px] absolute text-white text-4xl font-bold font-['PT Mono'] leading-10">
            Map3
          </div>
          <div className="w-[510px] left-[200px] top-[3840px] absolute text-white text-xl font-normal font-['PT Sans'] leading-normal">
            It is a map that allows you to explore Web3 activities in the real
            world. It provides a more personalized experience by checking places
            where the Web3 community gathers, including F&B stores, and
            analyzing user behavior patterns. Map3 is linked to blockchain games
            to help the ecosystem within the game connect with the real world.
          </div>
          <video
            className="w-[366px] h-80 left-[802px] top-[3803px] absolute"
            src={MonitorVideo}
            autoPlay
            loop
            muted
            playsInline
          >
            Your browser does not support the video tag.
          </video>
          <div className="left-[730px] top-[4800px] absolute">
            <div className="left-0 top-0 absolute text-[#ef5230] text-xl font-normal font-['PT Sans'] leading-normal whitespace-nowrap mt-6 flex items-center">
              <span>More Information</span>
              <img
                src={RightArrowImage}
                alt="Right Arrow"
                className="ml-2 w-4 h-4"
              />
            </div>
          </div>
          <div className="h-[356px] pl-[400px] pr-[200px] py-2.5 left-0 top-[1086px] absolute flex-col justify-center items-start gap-2.5 inline-flex">
            <div className="self-stretch h-[325px] flex-col justify-start items-end flex">
              <div className="self-stretch h-[180px] flex-col justify-start items-start flex">
                <div className="w-[820px]">
                  <span
                    style={{
                      color: "#ef5230",
                      fontSize: "32px",
                      fontWeight: "bold",
                      fontFamily: "PT Sans",
                      lineHeight: "84px",
                    }}
                  >
                    Lack of connectivity{" "}
                  </span>
                  <span
                    style={{
                      color: "white",
                      fontSize: "32px",
                      fontWeight: "bold",
                      fontFamily: "PT Sans",
                      lineHeight: "84px",
                    }}
                  >
                    between Web3 and the real world
                  </span>
                </div>
                <div className="self-stretch h-24 text-white text-xl font-normal font-['PT Sans'] leading-normal">
                  Web3 is still mainly trapped in the digital world and lacks
                  connection with the real world. As a result, the potential of
                  Web3 is not fully demonstrated, and it is becoming difficult
                  for ordinary users to experience the benefits of Web3
                  technology in real life.
                </div>
              </div>
              <div className="self-stretch h-[156px] flex-col justify-start items-start flex">
                <div className="self-stretch">
                  <span
                    style={{
                      color: "#ef5230",
                      fontSize: "32px",
                      fontWeight: "bold",
                      fontFamily: "PT Sans",
                      lineHeight: "84px",
                    }}
                  >
                    Low sustainability
                  </span>
                  <span
                    style={{
                      color: "white",
                      fontSize: "32px",
                      fontWeight: "bold",
                      fontFamily: "PT Sans",
                      lineHeight: "84px",
                    }}
                  >
                    {" "}
                    of token compensation models
                  </span>
                </div>
                <div className="self-stretch text-white text-xl font-normal font-['PT Sans'] leading-normal">
                  While many web3 projects reward users with tokens, this can
                  lead to token depreciation and inflation problems in the long
                  run. This destabilizes the project's economic foundation,
                  reduces user engagement, and eventually threatens its
                  viability.
                </div>
              </div>
            </div>
          </div>

          <video
            className="w-[510px] h-[510px] left-[200px] top-[4455px] absolute animate-fallAndFade"
            src={SAVTokensVideo}
            autoPlay
            loop
            muted
            playsInline
            loading="lazy"
            preload="none"
          >
            Your browser does not support the video tag.
          </video>
          <div className="h-[54px] p-2.5 left-[719px] top-[3570.78px] absolute flex-col justify-start items-start gap-2.5 inline-flex mt-8">
            <a
              href="https://docs.hidemeplease.xyz/"
              target="_blank"
              rel="noopener noreferrer"
              className="group relative inline-block border border-[#ef5230] rounded-[50px]"
            >
              <div
                className="text-white text-base font-normal font-['PT Sans'] leading-tight 
                    px-6 py-3 rounded-full
                    bg-black transition-all duration-300 ease-in-out cursor-pointer
                    group-hover:bg-[#ef5230]"
              >
                HMP Ecosystem Onboarding Guide
              </div>
              <div
                className="absolute inset-0 border border-[#ef5230] rounded-full opacity-0
                    transition-all duration-300 ease-in-out
                    group-hover:opacity-100 group-hover:inset-x-[-1px] group-hover:inset-y-[-1px]"
              ></div>
            </a>
          </div>
          <div className="h-[54px] p-2.5 left-[719px] top-[3516.78px] absolute flex-col justify-start items-start gap-2.5 inline-flex mt-6">
            <a
              href="https://galvanized-radon-a17.notion.site/5e856988fc3448fd9ace808eca571694?v=21923ed00762415cb4fedb39e08a3c03"
              target="_blank"
              rel="noopener noreferrer"
              className="group relative inline-block border border-[#ef5230] rounded-[50px]"
            >
              <div
                className="text-white text-base font-normal font-['PT Sans'] leading-tight 
                    px-6 py-3 rounded-full
                    bg-black transition-all duration-300 ease-in-out cursor-pointer
                    group-hover:bg-[#ef5230]"
              >
                See Our Partnership Restaurants
              </div>
              <div
                className="absolute inset-0 border border-[#ef5230] rounded-full opacity-0
                    transition-all duration-300 ease-in-out
                    group-hover:opacity-100 group-hover:inset-x-[-1px] group-hover:inset-y-[-1px]"
              ></div>
            </a>
          </div>
          <div className="w-full absolute top-[4145px] text-center text-white text-xl font-normal font-['PT Sans'] leading-normal">
            <video
              className="w-full h-full object-cover"
              src={PartnersLoopAnimation}
              autoPlay
              loop
              muted
              playsInline
              loading="lazy"
              preload="none"
            >
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="w-[1005.48px] h-[236.51px] top-[5125px] left-[200px] relative">
            <div className="text-white text-4xl font-bold font-['PT Mono'] leading-[72px] mb-2">
              Partners
            </div>
            <div className="text-white text-xl font-normal font-['PT Sans'] leading-tight">
              Many companies are joining our company's vision.
            </div>
          </div>
          <div className="w-[1005.48px] h-[236.51px] top-[5125px] left-[200px] relative">
            <div className="w-[231.72px] h-[39.26px] left-0 top-[27.40px] absolute">
              <img
                src={WorldCoinLogoImage}
                alt="WorldCoin Logo"
                className="w-full h-full object-contain"
                loading="lazy"
                decoding="async"
              />
            </div>
            <div className="w-[210.18px] h-[42.94px] left-[407px] top-[29.89px] absolute">
              <img
                src={WalkerhillLogoImage}
                alt="Walkerhill Logo"
                className="w-full h-full object-contain"
                loading="lazy"
                decoding="async"
              />
            </div>
            <div className="w-[192.48px] h-[67.73px] left-[813px] top-0 absolute">
              <img
                src={SKNetworksLogoImage}
                alt="SK Networks Logo"
                className="w-full h-full object-contain"
                loading="lazy"
                decoding="async"
              />
            </div>
            <div className="w-[151.77px] h-[78.51px] left-[43px] top-[158px] absolute">
              <img
                src={TheInventionLabLogoImage}
                alt="The Invention Lab Logo"
                className="w-full h-full object-contain"
                loading="lazy"
                decoding="async"
              />
            </div>
            <div className="w-[180.08px] h-[59.79px] left-[422px] top-[173.50px] absolute">
              <img
                src={IOTrustLogoImage}
                alt="IO Trust Logo"
                className="w-full h-full object-contain"
                loading="lazy"
                decoding="async"
              />
            </div>
            <div className="w-[192.48px] h-[67.73px] left-[813px] top-[173.50px] absolute">
              <img
                src={HuntTownImage}
                alt="Hunt Town Logo2"
                className="w-full h-full object-contain"
                loading="lazy"
                decoding="async"
              />
            </div>
          </div>

          <div className="left-[465px] top-[3197.78px] absolute flex-col justify-end items-center inline-flex">
            <div className="h-[172.92px] p-2.5 flex-col justify-end items-center gap-2.5 flex">
              <img
                className="self-stretch h-[152.92px] rounded-[20px] shadow animate-float-vibrate"
                src={HMPLogoImage}
                alt="HMP Logo"
                loading="lazy"
                decoding="async"
              />
            </div>
          </div>
          <video
            className="w-[1296px] h-[508px] left-[72px] top-[2510px] absolute"
            src={ProtocolEcosystemVideo}
            autoPlay
            loop
            muted
            playsInline
            loading="lazy"
            preload="none"
          >
            Your browser does not support the video tag.
          </video>

          <Footer scrollToTop={scrollToTop} />
        </div>
      </div>
    </div>
  );
}

export default Home;
