import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../images/Logo";
import LetterLogo from "../../images/LetterLogo";
import MenuIcon from "../../images/Menu.png";
import MenuBar from "./Menubar";

const Header = ({ scrollToTop }) => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  const handleLogoClick = () => {
    navigate("/");
    if (window.location.pathname === "/") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    if (scrollToTop) {
      scrollToTop();
    }
  };

  return (
    <>
      <div className="w-full h-[47px] fixed top-0 left-0 z-50 bg-black/80">
        {/* Center container with max-width */}
        <div className="max-w-[430px] h-full mx-auto relative">
          {/* Menu Icon */}
          <div
            className="absolute right-5 top-0 h-[47px] flex items-center justify-center cursor-pointer"
            onClick={toggleMenu}
          >
            <img
              src={MenuIcon}
              alt="Menu"
              className="w-5 h-5 transform scale-[1.1]"
            />
          </div>

          {/* Logo */}
          <div
            className="absolute left-6 top-[17px] flex items-center gap-2 cursor-pointer"
            onClick={handleLogoClick}
          >
            <div className="transform scale-[1.0] origin-left">
              <Logo />
            </div>
            <div className="transform scale-[1.0] origin-center">
              <LetterLogo />
            </div>
          </div>
        </div>
      </div>

      {/* Menu Overlay */}
      <div
        className={`fixed top-0 right-0 w-full h-full bg-black/50 flex justify-end z-50 transition-opacity duration-300 ${
          isMenuVisible ? "opacity-100 visible" : "opacity-0 invisible"
        }`}
        onClick={(e) => {
          if (e.target === e.currentTarget) {
            setMenuVisible(false);
          }
        }}
      >
        <div
          className={`w-[175px] h-full bg-black transform transition-transform duration-500 ease-out ${
            isMenuVisible ? "translate-x-0" : "translate-x-full"
          }`}
        >
          <MenuBar
            isMenuVisible={isMenuVisible}
            setMenuVisible={setMenuVisible}
          />
        </div>
      </div>
    </>
  );
};

export default Header;
