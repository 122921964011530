import React from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../images/Logo";
import LetterLogo from "../../images/LetterLogo";
import XIconImage from "../../images/XIcon.png";
import InstagramIconImage from "../../images/InstagramIcon.png";
import WhatsAppIconImage from "../../images/WhatsAppIcon.png";

function Footer({ scrollToTop }) {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0; // For Safari
  };

  return (
    <>
      <div className="w-[1440px] h-[270px] left-0 top-[6520px] absolute">
        <div className="w-[336px] h-[29.18px] left-[201px] top-[121px] absolute z-50">
          <div className="w-[29.82px] h-[29.18px] left-0 top-0 absolute icon-container z-50 group">
            <a
              href="https://x.com/map3protocol"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={XIconImage}
                alt="X Icon"
                className="w-full h-full object-contain opacity-50 group-hover:opacity-100 transition-opacity duration-300 cursor-pointer"
              />
            </a>
          </div>
          <div className="w-[29.82px] h-[29.18px] left-[40px] top-0 absolute icon-container z-50 group">
            <a
              href="https://www.instagram.com/hidemeplease_official/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={InstagramIconImage}
                alt="Instagram Icon"
                className="w-full h-full object-contain opacity-50 group-hover:opacity-100 transition-opacity duration-300 cursor-pointer"
              />
            </a>
          </div>
          <div className="w-[29.82px] h-[29.18px] left-[80px] top-0 absolute icon-container z-50 group">
            <img
              src={WhatsAppIconImage}
              alt="WhatsApp Icon"
              className="w-full h-full object-contain opacity-50 group-hover:opacity-100 transition-opacity duration-300"
            />
          </div>
          <div className="left-[125px] top-[2px] absolute opacity-50 text-white text-lg font-normal font-['PT Sans'] leading-tight ml-2">
            help@hidemeplease.xyz
          </div>
        </div>
        <div className="w-[1440px] h-[90px] left-0 top-[180px] absolute opacity-50 text-center text-[#ef5230] text-xl font-normal font-['PT Sans'] leading-9 mb-8">
          2025 map3protocol. All rights reserved.
        </div>
        <div className="w-full h-[0px] left-0 top-[77px] absolute border border-[#ef5230]/50"></div>
        <div className="w-[245px] h-[29px] left-[215px] top-0 absolute justify-start items-start gap-[10.88px] inline-flex">
          <div className="w-[204.41px] h-[29px] relative flex items-center -mx-2 cursor-pointer z-50">
            <div className="transform scale-[1.3] mr-8" onClick={scrollToTop}>
              <Logo />
            </div>
            <div className="transform scale-[1.3]" onClick={scrollToTop}>
              <LetterLogo />
            </div>
          </div>
        </div>

        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLScAdcz8GOOk35GUfDMN2nZTLu5ulzOQpnFNb_pEoSnfd8Y3nQ/viewform"
          target="_blank"
          rel="noopener noreferrer"
          className="group relative inline-block"
        >
          <div
            className="h-10 p-2.5 left-[1132px] top-[94px] absolute rounded-[30px] border border-[#ef5230] justify-center items-center gap-2.5 inline-flex 
            bg-black transition-all duration-300 ease-in-out cursor-pointer
            group-hover:bg-[#ef5230]"
          >
            <div className="text-right text-white text-base font-bold font-['PT Sans'] leading-tight whitespace-nowrap">
              Join our Map
            </div>
          </div>
        </a>
        <div className="h-5 left-[870px] top-[5px] absolute justify-end items-center gap-10 inline-flex">
          <div
            className="text-white text-xl font-bold font-['PT Sans'] leading-tight cursor-pointer z-50 relative"
            onClick={scrollToTop}
          >
            Home
          </div>
          <div
            className="text-white text-xl font-bold font-['PT Sans'] leading-tight cursor-pointer z-50"
            onClick={() => handleNavigation("/story")}
          >
            Story
          </div>
          <div
            className="text-white text-xl font-bold font-['PT Sans'] leading-tight cursor-pointer z-50"
            onClick={() => handleNavigation("/team")}
          >
            Team
          </div>
          <div
            className="text-white text-xl font-bold font-['PT Sans'] leading-tight cursor-pointer z-50"
            onClick={() => handleNavigation("/partnership")}
          >
            Partnership
          </div>
        </div>
      </div>
      <div className="w-[1440px] left-0 top-[6105px] absolute text-center text-[#ef5230] text-6xl font-bold font-['PT Sans'] leading-[72px] italic">
        Bringing Web3 to Life,
        <br />
        One Map at a Time
      </div>
    </>
  );
}

export default Footer;
