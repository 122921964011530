import React from "react";
import Header from "./Header";
import Map3LogoImage from "../../images/Map3Logo.png";
import StockImage from "../../images/StockImage.png";
import Map3BridgeImage from "../../images/Map3Bridge.png";
import MobileProtocolEcosystemImage from "../../images/MobileProtocolEcosystem.png";
import HMPApp2Image from "../../images/HMPApp2.png";
import HMPLogoImage from "../../images/HMPLogo.png";
import Map3Video from "../../images/Map3Video.mp4";
import PartnersLoopAnimationMobile from "../../images/PartnersLoopAnimationMobile.mp4";
import SAVTokensVideo from "../../images/SAVTokens.mp4";
import RightArrowImage from "../../images/RightArrow.png";
import Footer from "./Footer";

const MobileviewHome = () => {
  const handleExploreClick = () => {
    const targetPosition = 790; // Position of "WEB3's cool" section
    window.scrollTo({
      top: targetPosition,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className="flex justify-center items-center min-h-screen bg-black">
        <div
          className="w-full max-w-[430px] min-w-[360px] relative bg-black mx-auto"
          style={{ height: "5170px" }}
        >
          <Header />
          <div className="w-[90%] max-w-[430px] mx-auto left-[22px] top-[88px] absolute text-white text-3xl sm:text-[32px] font-bold font-['PT Sans'] leading-8 sm:leading-9">
            We Bring Web3 to <br />
            Real Life
          </div>
          <div className="w-52 left-[22px] top-[178px] absolute opacity-70 text-white text-[13px] sm:text-sm font-normal font-['PT Sans'] leading-1.5">
            It is a protocol that allows you to use your NFT as a membership
            that allows you to receive various benefits in real-world stores.
          </div>

          <img
            className="w-full h-[360px] left-[0px] top-[406px] absolute origin-top-left bg-gradient-to-b from-black to-black object-cover absolute z-0 opacity-40"
            src={StockImage}
            alt="Stock"
            loading="lazy"
            decoding="async"
          />
          <div className="w-full flex justify-center items-center absolute top-[720px]">
            <div
              className="flex flex-col items-center gap-2 cursor-pointer"
              onClick={handleExploreClick}
            >
              <div className="text-center text-white text-sm font-normal font-['PT Mono'] leading-[18px] animate-pulse">
                Explore More
              </div>
              <div className="w-0 h-0 border-l-[8px] border-l-transparent border-r-[8px] border-r-transparent border-t-[8px] border-t-white animate-bounce"></div>
            </div>
          </div>
          <div className="left-[22px] top-[901px] absolute text-white text-[32px] font-bold font-['PT Sans'] leading-9">
            WEB3's cool, but not <br /> practical yet...
          </div>
          <div className="w-[90%] mx-auto left-[22px] top-[1001px] absolute">
            <span
              style={{
                color: "#ef5230",
                fontSize: "16px",
                fontWeight: "bold",
                fontFamily: "PT Sans",
                lineHeight: "18px",
                display: "block",
                marginBottom: "-4px",
              }}
            >
              Lack of connectivity
              <br />
            </span>
            <span
              style={{
                color: "white",
                fontSize: "16px",
                fontWeight: "bold",
                fontFamily: "PT Sans",
                lineHeight: "18px",
              }}
            >
              between Web3 and the real world
            </span>
          </div>
          <div className="w-[90%] mx-auto left-[22px] top-[1045px] absolute opacity-70 text-white text-xs font-normal font-['PT Sans'] leading-1">
            Web3 is still mainly trapped in the digital world and lacks
            connection with the real world. As a result, the potential of Web3
            is not fully demonstrated, and it is becoming difficult for ordinary
            users to experience the benefits of Web3 technology in real life.
          </div>
          <div className="w-[90%] mx-auto left-[22px] top-[1156px] absolute">
            <span
              style={{
                color: "#ef5230",
                fontSize: "16px",
                fontWeight: "bold",
                fontFamily: "PT Sans",
                lineHeight: "18px",
                display: "block",
                marginBottom: "-4px",
              }}
            >
              Low sustainability
              <br />
            </span>
            <span
              style={{
                color: "white",
                fontSize: "16px",
                fontWeight: "bold",
                fontFamily: "PT Sans",
                lineHeight: "18px",
              }}
            >
              of token compensation models
            </span>
          </div>

          <div className="w-[90%] mx-auto left-[22px] top-[1200px] absolute opacity-70 text-white text-xs font-normal font-['PT Sans'] leading-1">
            While many web3 projects reward users with tokens, this can lead to
            token depreciation and inflation problems in the long run. This
            destabilizes the project's economic foundation, reduces user
            engagement, and eventually threatens its viability.
          </div>

          <div className="w-[90%] mx-auto left-[22px] top-[3342px] absolute opacity-70 text-white text-xs font-normal font-['PT Sans'] leading-none">
            Hide Me Please(HMP) is an app that allows users to register their
            NFTs as membership cards and enjoy discounts at affiliated F&B
            stores around the world. You can enjoy stores with cryptocurrency
            payments, and users can check their favorite members' activities at
            affiliated stores in real time.
          </div>
          <div className="w-[90%] mx-auto left-[22px] top-[4002px] absolute opacity-70 text-white text-xs font-normal font-['PT Sans'] leading-1 z-10">
            It is a map that allows you to explore Web3 activities in the real
            world. It provides a more personalized experience by checking places
            where the Web3 community gathers, including F&B stores, and
            analyzing user behavior patterns. Map3 is linked to blockchain games
            to help the ecosystem within the game connect with the real world.
          </div>
          <div className="w-[90%] mx-auto left-[22px] top-[4907px] absolute opacity-70 text-white text-xs font-normal font-['PT Sans'] leading-1">
            H2E emphasizes interactive hiding activities using NFC tags at
            affiliated stores, allowing users to mine tokens and earn unique
            rewards beyond simple visits.
          </div>

          <div className="bg-gradient-to-b from-[#F43111] via-[#EF5230] to-[#F43111] absolute left-0 top-[1391px] w-full h-[870px] z-0"></div>
          <div className="relative left-0 top-[1391px] w-full h-[870px] z-0">
            <div className="absolute inset-0 bg-gradient-to-b from-black via-transparent to-transparent h-[200px]"></div>
            <div className="absolute inset-x-0 bottom-0 bg-gradient-to-b from-transparent to-black h-[200px]"></div>
          </div>
          <img
            className="w-[358px] h-[141px] absolute left-1/2 top-[1707px] transform -translate-x-1/2"
            src={Map3BridgeImage}
            alt="Map3 Bridge"
            loading="lazy"
            decoding="async"
          />
          <div className="left-[22px] top-[1627px] absolute text-black text-[32px] font-bold font-['PT Sans'] leading-9">
            Make a<br />
            New Connection
          </div>
          <div className="w-[90%]  mx-auto left-[22px] top-[1860px] absolute text-black text-base font-bold font-['PT Sans'] leading-[18px]">
            Map3 technology effectively integrates Web3 with the Real World.
          </div>
          <div className="w-full text-center absolute top-[2337px] text-white text-[28px] font-bold font-['PT Mono'] leading-1">
            Map3Protocol
            <br />
            Ecosystem{" "}
          </div>
          <div className="w-[90%] mx-auto left-[22px] top-[1914px] absolute text-black text-xs font-normal font-['PT Sans'] leading-1">
            Building a sustainable token economy model is essential to the
            long-term success of the Web3 ecosystem, and its integration with
            the real world is an important factor that promotes popularization
            and is easily accessible to more users.
          </div>
          <div className="w-full text-center absolute top-[2968px] text-white text-[28px] font-bold font-['PT Mono'] leading-loose">
            HMP App
          </div>
          <div className="w-full text-center absolute top-[3729px] text-white text-[28px] font-bold font-['PT Mono'] leading-7">
            Map3
          </div>
          <div className="w-full text-center absolute top-[4462px]">
            <span
              style={{
                color: "white",
                fontSize: "28px",
                fontWeight: "bold",
                fontFamily: "PT Sans",
                lineHeight: "28px",
              }}
            >
              Tokenomics
              <br />
            </span>
            <span
              style={{
                color: "white",
                fontSize: "20px",
                fontWeight: "bold",
                fontFamily: "PT Sans",
                lineHeight: "28px",
              }}
            >
              Hide to Earn (H2E)
            </span>
          </div>
          <img
            className="w-full max-w-[312.32px] h-auto left-1/2 top-[3069px] absolute"
            style={{
              transform: "translateX(-50%) rotate(13.74deg)",
            }}
            src={HMPApp2Image}
            alt="HMP App Interface"
            loading="lazy"
            decoding="async"
          />

          <img
            className="w-[83px] h-[83px] left-2/3 top-[3079.91px] absolute rounded-[10px] shadow transform -translate-x-1/2"
            src={HMPLogoImage}
            alt="HMP Logo"
            loading="lazy"
            decoding="async"
          />
          <div className="left-[22px] top-[3431px] absolute text-[#ef5230] text-xs font-normal font-['PT Sans'] leading-[14px]">
            F&B Partners
          </div>
          <div className="left-[22px] top-[3450.08px] absolute text-[#ef5230] text-2xl font-bold font-['PT Sans'] leading-normal">
            130 +
          </div>
          <div className="left-[130px] top-[3431px] absolute text-[#ef5230] text-xs font-normal font-['PT Sans'] leading-[14px]">
            Users
          </div>
          <div className="left-[130px] top-[3450px] absolute text-[#ef5230] text-2xl font-bold font-['PT Sans'] leading-normal">
            15,300 +
          </div>
          <a
            href="https://docs.hidemeplease.xyz/"
            target="_blank"
            rel="noopener noreferrer"
            className="absolute left-[22px] top-[3546px] inline-block"
          >
            <div className="p-2.5 rounded-[50px] border border-[#ef5230] justify-center items-center gap-2.5 inline-flex hover:bg-[#ef5230]/10 transition-colors">
              <div className="text-white text-[10px] font-normal font-['PT Sans'] leading-3">
                HMP Ecosystem Onboarding Guide
              </div>
            </div>
          </a>
          <a
            href="https://galvanized-radon-a17.notion.site/5e856988fc3448fd9ace808eca571694?v=21923ed00762415cb4fedb39e08a3c03"
            target="_blank"
            rel="noopener noreferrer"
            className="absolute left-[22px] top-[3502px] inline-block"
          >
            <div className="p-2.5 rounded-[50px] border border-[#ef5230] justify-center items-center gap-2.5 inline-flex hover:bg-[#ef5230]/10 transition-colors">
              <div className="text-white text-[10px] font-normal font-['PT Sans'] leading-3">
                Find out Partnership Restaurants
              </div>
            </div>
          </a>
          <div className="left-[240px] top-[3502px] absolute flex-col justify-start items-start gap-2.5 inline-flex">
            <a
              href="https://play.google.com/store/apps/details?id=com.kr.hideme&hl=en"
              target="_blank"
              rel="noopener noreferrer"
              className="p-2.5 rounded-[50px] border border-[#ef5230] justify-center items-center gap-2.5 inline-flex cursor-pointer hover:bg-[#ef5230]/10 transition-colors"
            >
              <div className="text-white text-[10px] font-normal font-['PT Sans'] leading-3">
                Android Install
              </div>
            </a>
          </div>
          <div className="left-[240px] top-[3546px] absolute flex-col justify-start items-start gap-2.5 inline-flex">
            <a
              href="https://apps.apple.com/us/app/%ED%95%98%EC%9D%B4%EB%93%9C%EB%AF%B8%ED%94%8C%EB%A6%AC%EC%A6%88/id1663171012"
              target="_blank"
              rel="noopener noreferrer"
              className="p-2.5 rounded-[50px] border border-[#ef5230] justify-center items-center gap-2.5 inline-flex cursor-pointer hover:bg-[#ef5230]/10 transition-colors"
            >
              <div className="text-white text-[10px] font-normal font-['PT Sans'] leading-3">
                IOS Install
              </div>
            </a>
          </div>

          <div className="w-[90%] max-w-[363px] mx-auto left-1/2 top-[3786px] absolute transform -translate-x-1/2">
            <video className="w-full h-auto" autoPlay loop muted playsInline>
              <source src={Map3Video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="w-[100%] mx-auto top-[4535px] absolute">
            <video className="w-full h-[350px]" autoPlay loop muted playsInline>
              <source src={SAVTokensVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="w-[100%] mx-auto top-[4162px] absolute">
            <video
              style={{
                height: "200px",
                objectFit: "cover",
                width: "100vw",
              }}
              autoPlay
              loop
              muted
              playsInline
            >
              <source src={PartnersLoopAnimationMobile} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="h-[153.97px] left-[calc(68%-77px)] top-[271px] absolute flex-col justify-end items-center gap-2.5 inline-flex">
            <img
              className="self-stretch h-[153.97px]"
              src={Map3LogoImage}
              alt="Map3 Logo"
              loading="lazy"
              decoding="async"
            />
          </div>
          <img
            className="w-[317px] h-[361px] left-1/2 top-[2480px] absolute transform -translate-x-1/2"
            src={MobileProtocolEcosystemImage}
            alt="Protocol Ecosystem"
            loading="lazy"
            decoding="async"
          />
          <div className="w-full text-center absolute top-[4981px]">
            <div className="flex justify-center items-center gap-2">
              <div className="text-[#ef5230] text-sm font-normal font-['PT Sans'] leading-[14px] whitespace-nowrap">
                More Information
              </div>
              <img
                src={RightArrowImage}
                alt="Right Arrow"
                className="w-2 h-2"
                loading="lazy"
                decoding="async"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MobileviewHome;
